import {Action, Reducer} from 'redux';
import { IMAGEDELETING, IMAGEDELETEDFAILED,IMAGEDELETED, CLAIMNEWTENDERPERIODLOADING, CLAIMNEWTENDERPERIODLOADED, ADDPARTSLOADING,ADDPARTSLOADED,PARTSLOADING, PARTSLOADED, POLICIESLOADING, POLICIESLOADED,  VEGHICLEMODELLOADING, VEGHICLEMODELLOADED, VEGHICLESUBMODELLOADED, VEGHICLESUBMODELLOADING,PRICEOFFERLOADING, STATUSUPDATING, STATUSUPDATED, PRICEOFFERLOADED,IMAGEUPLOADED,IMAGEUPLOADING, PARTREMOVING, PARTREMOVED, CLAIMLOADING,REPORTLOADING,REPORTLOADED, CLAIMLOADED, CLAIMPARTSLOADING, CLAIMPARTSLOADED, CLAIMSLISTSLOADED, PRICEOFFERUPDATING, PRICEOFFERUPDATED, PRICEOFFERDELETING, PRICEOFFERDELETED, SELLERSLINKSLOADED, SELLERSLINKSLOADING} from './EditOrder.actions';

import {initialState} from './EditOrder.state';

export const EditOrderReducer: Reducer<any, Action> = ( state = initialState, action: any) => {
    let newState = state
    switch (action.type) {
        case PRICEOFFERLOADING :  newState={...newState,offerLoading:true}; break;
        case PRICEOFFERLOADED :  newState={...newState,offerLoading:false, priceOffer:action.payload}; break;
        case CLAIMLOADING :  newState={...newState,loading:true}; break;
        case CLAIMLOADED :  newState={...newState,loading:false, claim:action.payload}; break;
        case CLAIMPARTSLOADING :  newState={...newState,loading:true}; break;
        case CLAIMPARTSLOADED :  newState={...newState,loading:false, claimParts:action.payload}; break;
        case REPORTLOADING :  newState={...newState,loading:true}; break;
        case REPORTLOADED :  newState={...newState,loading:false, report:action.payload}; break;
        case PARTREMOVING :  newState={...newState,removing:true}; break;
        case PARTREMOVED :  newState={...newState,removing:false, removed:action.payload}; break;
        case IMAGEUPLOADING :  newState={...newState,uploading:true}; break;
        case IMAGEUPLOADED :  newState={...newState,uploading:false, uploaded:action.payload}; break;
        case STATUSUPDATING :  newState={...newState,updating:true}; break;
        case STATUSUPDATED :  newState={...newState,updating:false, updated:action.payload}; break;
        case VEGHICLEMODELLOADING : newState={...newState,loading:true}; break;
        case VEGHICLEMODELLOADED : newState={...newState,loading:false, vehicleModels:action.payload}; break;
        case VEGHICLESUBMODELLOADING : newState={...newState,subModelLoading:true}; break;
        case VEGHICLESUBMODELLOADED : newState={...newState,subModelLoading:false, vehicleSubModels:action.payload}; break;
        case POLICIESLOADING : newState={...newState,lpolyciesLoading:true}; break;
        case POLICIESLOADED : newState={...newState,lpolyciesLoading:false, policies:action.payload}; break;
        case PARTSLOADING : newState={...newState,loading:true}; break;
        case PARTSLOADED : newState={...newState,loading:false, parts:action.payload}; break;
        case ADDPARTSLOADING : newState={...newState,loading:true}; break;
        case ADDPARTSLOADED : newState={...newState,loading:false}; break;
        case CLAIMNEWTENDERPERIODLOADING: newState={...newState,loading:true}; break;
        case CLAIMNEWTENDERPERIODLOADED: newState={...newState,loading:false}; break;
        case IMAGEDELETING :  newState={...newState,deleted:true,uploading:true, loading:true }; break;
        case IMAGEDELETED :  newState={...newState,deleted:false,uploading:false, loading:false}; break;
        case IMAGEDELETEDFAILED :  newState={...newState,deleted:false,uploading:false, loading:false}; break;
        case CLAIMSLISTSLOADED :  newState={...newState,loading:false, lists:action.payload}; break;
        case PRICEOFFERUPDATING : newState={...newState, priceOfferUpdating:true}; break;
        case PRICEOFFERUPDATED : newState={...newState, priceOfferUpdating:false}; break;
        case PRICEOFFERDELETING : newState={...newState, priceOfferDeleting:true}; break;
        case PRICEOFFERDELETED : newState={...newState, priceOfferDeleting:false}; break;
        case SELLERSLINKSLOADING: newState={...newState, sellerLinksLoading: true}; break;
        case SELLERSLINKSLOADED: newState={...newState, sellerLinksLoading: false, nonParticipatedSellerLinks: action.payload?.result}; break;
    }
    return newState;
};
